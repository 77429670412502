import React, { Fragment } from "react";
import { Head } from "react-static";

import Quote from "../components/hero-quote";

export default () => (
  <Fragment>
    <Head>
      <title>Not Found</title>
    </Head>
    <Quote>
      404! In other words, we can't find the page you were looking for.
    </Quote>
  </Fragment>
);
